











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_ACCOUNTING, REMOVE_ANNUAL_ACCOUNTING } from '../../../store';
import { Accounting, RemoveAnnualAccounting } from '../../../types';
import { ApiResponse } from '@/components/types';
import { MapAccountingToRemoveCommand } from '@/modules/listOfAssets/model';

const ListOfAssets = namespace('listOfAssets');

@Component({})
export default class CorrectAnnualAccountingDialog extends Vue {
  @Prop({ type: Object }) accounting!: Accounting | null;

  @ListOfAssets.Action(REMOVE_ANNUAL_ACCOUNTING) removeAnnualAccounting!: (asset: RemoveAnnualAccounting) => Promise<ApiResponse>;
  @ListOfAssets.Action(FETCH_ACCOUNTING) fetchAccounting!: ({ listOfAssetsId, id }: { listOfAssetsId: string, id: string }) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;
  value: RemoveAnnualAccounting | null = null;

  file: string = '';

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.file = '';
      this.dialog = false;
      this.loading = false;
      this.value = null;
      this.$emit('update:accounting', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('accounting')
  watchAsset(accounting: Accounting) {
    if (!accounting) return;

    this.value = MapAccountingToRemoveCommand(accounting);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.removeAnnualAccounting(this.value);
  }
}
